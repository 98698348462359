<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Supervising The Websites And Servers</h2>
      <p class="desc">
        Network and performance management also includes the overseeing of
        websites and servers. It is done in the following ways:
      </p>
      <ul>
        <li>
          <p>
            Keep a record of the experience of user applications and websites
            from 60+ places all over the world.
          </p>
        </li>
        <li>
          <p>
            Displays open status pages for an application program interface(API)
            about your administration.
          </p>
        </li>
        <li>
          <p>
            Supervises all the apps and web administration activities from
            places that are nearby your clients.
          </p>
        </li>
        <li>
          <p>
            Their ability to supervise the engine applications manager is cherry
            on top, which helps in the monitoring of a user’s experience.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/network-performance/website-and-server.jpg"
        alt="Supervising The Websites And Servers"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-net-per-section",
};
</script>
