<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">
        Supervision Of Servers And Application Performance
      </h2>
      <ul>
        <li>
          <p>
            You can easily acquire information about application servers,
            servers database, web services, transactions, and applications
            related to cloud in your data section.
          </p>
        </li>
        <li>
          <p>
            If the traffic jam is about to happen in the assembly line, it
            detects it, identifies its cause, and provides the solution before
            it starts showing its bad impact.
          </p>
        </li>
        <li>
          <p>
            Monitors user activities through real user monitoring; therefore, it
            is familiar with user experience on different apps and ensures a
            good experience on all the web apps.
          </p>
        </li>
        <li>
          <p>
            To help you plan for upgrading business, it provides a lot of space
            for brainstorming and information about the latest trends so you can
            plan in a better way.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100 mt-4"
        src="../../../../assets/it-software-management/network-performance/server-and-application.jpg"
        alt="Supervision Of Servers And Application Performance"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-net-per-section",
};
</script>
