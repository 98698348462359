<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Management Of Network Configuration</h2>
      <p class="desc">
        Managing the configurations of the network is an important subunit of
        network and performance management and has many purposes of the
        networkChange, configuration(s), and compliance management [_NCCCM_]. It
        proposes a solution(s) for all kinds of device or devices, including
        routers and firewalls. NCM helps in solving all kinds of problems by
        automatically controlling every activity of the device.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/network-performance/network-configuration.jpg"
        alt="Management Of Network Configuration"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-net-per-section",
};
</script>
