<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        The Management Of Switch Port And Internet Protocol(IP) Address
      </h2>
      <p class="desc">
        There is software termed as OpUtils, which helps in managing the switch
        port and IP address, thereby fulfilling the requirements of network and
        performance management. It helps the clients to manage everything
        effectively and easily. This software consists of 30+ tools that help in
        supervising the network jobs, including identifying a rogue gadget
        interruption, checking usage of bandwidth, overseeing the availability
        of critical devices, backing up Cisco configuration files, and many
        more.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/network-performance/dns-and-ip.jpg"
        alt="The Management Of Switch Port And Internet Protocol(IP) Address"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-net-per-section",
};
</script>
