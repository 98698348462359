<template>
  <network-performance />
</template>

<script>
import NetworkPerformance from "../../components/solutions/it-software-management/NetworkPerformance.vue";
export default {
  name: "network-performance-page",
  components: { NetworkPerformance },
};
</script>
