<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Handling the Structure of Data Center and Network
      </h2>
      <p>
        Network and performance management is carried out with the help of
        software.
      </p>
      <ul>
        <li>
          <p>
            This software is used to handle the structure of the data center and
            network of big firms, administration providers, and [SMEs].
          </p>
        </li>
        <li>
          <p>
            Automatically working processes, sharp warning motors, adapted
            disclosure code of conduct, or long formats.
          </p>
        </li>
        <li>
          <p>User-friendly programs and software run effectively and easily.</p>
        </li>
        <li>
          <p>
            Snap-in for the modification of network then design the board,
            Internet Protocol address the handling likewise system, apps,
            database, and the user can only see the abstraction of computer and
            bandwidth supervision.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/network-performance/network-and-data-center.jpg"
        alt="Handling the Structure of Data Center and Network"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-net-per-section",
};
</script>
