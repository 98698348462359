<template>
  <LeftSection>
    <div class="info-desc col-lg-8 mt-5">
      <h2 class="title-orange h1">
        The Management Of Network Traffic Analysis and Bandwidth
      </h2>
      <ul>
        <li>
          <p>
            Managing the analysis of traffic analysis and the bandwidth is also
            an important constituent of network and performance management.
          </p>
        </li>
        <li>
          <p>
            Tools for traffic, security analysis, and supervising bandwidth are
            used, which gives the complete details about the functioning of
            bandwidth.
          </p>
        </li>
        <li>
          <p>
            Monitors the bandwidth in a way that has all information about the
            user who is using the bandwidth and purpose of using it.
          </p>
        </li>
        <li>
          <p>
            Detects the problems like a traffic jam of network or system, QoS,
            SLA issues, security anomalies, and many more before it happens and
            provide the solutions to solve them.
          </p>
        </li>
      </ul>
      <p>
        It also supports Cisco and its compatible techniques, for instance, Net
        flow, WAAS, AP SLA, and
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100 mt-4"
        src="../../../../assets/it-software-management/network-performance/network-traffic-analysis.jpg"
        alt="The Management Of Network Traffic Analysis and Bandwidth"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-net-per-section",
};
</script>
